@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');

body{
  font-family: 'Manrope', sans-serif !important;
}
.nav{
  background-color: white;
  display: flex;
  justify-content: space-between;
}
#selectedTab:hover,.contact:hover, a:hover{
  border-bottom: 2px solid black;
}

#menutoggle {
  opacity: 0;
}
#menutoggle:checked + .menubtn > span {
  transform: rotate(45deg);
}
#menutoggle:checked + .menubtn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#menutoggle:checked + .menubtn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menutoggle:checked ~ .menubox {
  left: 0 !important;
}
.menubtn {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 3;
}
.menubtn > span,
.menubtn > span::before,
.menubtn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #616161;
  transition-duration: .25s;
}
.menubtn > span::before {
  content: '';
  top: -8px;
}
.menubtn > span::after {
  content: '';
  top: 8px;
}
.menubox {
  display: block;
  position: fixed;
  top: 0;
  left: -100%;
  width: 300px;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  z-index: 2;
  list-style: none;
  background-color: #ECEFF1;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
  transition-duration: .25s;
}
.menuitem {
  display: block;
  padding: 12px 24px;
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: .25s;
}
.menuitem:hover {
  background-color: #CFD8DC;
}
.ourTeam{
  margin-top: -7vw;
}
.contactForm{
  width: 50vw;
  margin-left: 23vw;
  margin-top: 5vw;
}
.contactFormBTN{
  width: 7vw;
  margin-top: 1.2vw;
  margin-left: 31%;
  height: 1.5vw;
}
#copy{
  font-size: 1.5vw;
  vertical-align: text-top;
}
.logo{
  margin-bottom: -4vw;
  /* width: 6vw; */
  position: relative;
  margin-left: 46vw;
  margin-top: 1vw;
  z-index: 6;
}
.footer{
  position: relative;
}

#animations-example-4 {
  /* height: 25vw; */
  width: 47vw;
  margin: 0 auto;
}


tbody{
  width: 50vw;
  display: flex;
  justify-content: space-between;
}

.circle {
  border: 3px solid black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 2vw -1vw 7vw 0vw;
  display: flex;
  animation: circle 2s;
  animation-fill-mode: forwards;
  justify-content: space-between;
  opacity: 0;
}

.data{
  width: 7vw;
  display: block;
  opacity: 0;
  animation: circle 2s;
  animation-fill-mode: forwards;
}
@keyframes circle {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: scale(1,1);
  }
}
.line{
    background-color: #000000;
    height: 4px;
    width: 9.23vw;
    position: absolute;
    margin-top: 2.75vw;
    margin-left: 1.5vw;
    border-top-right-radius: 1vw;
    animation: LineGrow 2s;
    animation-fill-mode: forwards;
    border-bottom-right-radius: 1vw;
    opacity: 0;
    visibility: visible;
}
.line1{
  opacity: 1;
  animation-delay: 0;
}
.line2{
  animation-delay: 2s;
}
.line3{
  animation-delay: 4s;
}
.line4{
  animation-delay: 6s;
}
.line5{
  animation-delay: 8s;
}

@keyframes LineGrow {
  from {
    transform: scale(0,0);
  }
  to {
    opacity: 1;
    transform: scale(1,1);
  }
}

.pricing{
  margin-top: 6vw;
}


@media only screen and (max-width: 600px) {
  tbody{
    display: inline;
  }
  .line{
    display: none;
  }
  .circle{
    margin: 4vw 1vw 4vw -17vw;
    width: 25px;
    height: 25px;
  }
  .data{
    width: 50vw;
  }
  .whyUs{
    margin-top: -29vw;
    margin-bottom: 8vw;
  }
  .images{
    width: 105vw !important;
  }
  .columns-2 {
    columns: 3 !important;
}
.logo {
  margin-bottom: -23vw;
  width: 36vw;
  position: relative;
  margin-left: 31vw;
  margin-top: 3vw;
  z-index: 6;
}
}
